import React from 'react';
import TransportIcon from '../assets/img/transport_icon.svg';
import ProfileBar from '../profiles/Components/ProfileBar';
import NavigateCard from './components/NavigateCard';
import Media from './components/Media';
import { faUser, faPhoneAlt, faBed } from '@fortawesome/free-solid-svg-icons';
import Loader from '../components/Loader';
import { useTransportDetails } from './queries/actHooks';
import { useUserProfile } from '../profiles/queries/profileHooks';
import CardOpener from './components/CardOpener';
function Transport() {
  const { data: profile } = useUserProfile();
  const { data, isLoading } = useTransportDetails({ user_id: profile.message.sfa_id });

  const transports = data;

  return (
    <div>
      <ProfileBar head="Transport" act={true} />
      <div className="mx-6 mb-5 mt-6 max-w-full bg-white flex flex-col gap-6">
        {isLoading ? (
          <Loader />
        ) : !transports.length || transports.length === 0 ? (
          <div data-cy="transport_empty_msg" className="p-20 text-center text-l font-bold">
            Transportation is not alloted.
          </div>
        ) : (
          <>
            {transports.length === 1 ? (
              <div className="mt-6 max-w-full bg-white px-6 py-4  font-roboto rounded-lg">
                <div className="flex items-center pb-4 border-b border-fade-gray  gap-4">
                  <img src={TransportIcon} alt="transport-icon" />
                  <div>
                    <p className="text-xs text-deep-gray">Vehcile Type</p>
                    <p data-cy="transport_type" className="text-small font-bold text-dark-gray">
                      {transports[0].transport_type}
                    </p>
                    {transports[0].vehicle_number ? (
                      <button
                        data-cy="vehicle_number"
                        className="text-xxs blueBadge font-bold mt-1"
                      >
                        {transports[0].vehicle_number}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="pb-4 border-b border-fade-gray">
                  <Media
                    icon={faUser}
                    header="Contact Person"
                    content={transports[0].contact_person}
                  />
                  <Media icon={faPhoneAlt} header="Contact" content={transports[0].contact} />
                  {/* <Media
                    icon={faMapMarkerAlt}
                    header="Pickup Point"
                    content={transports[0].venue}
                  /> */}
                </div>
                <div className="text-center">
                  <p data-cy="transport_remark" className="text-xs text-deep-gray pt-4">
                    {transports[0].remark}
                  </p>
                </div>
              </div>
            ) : (
              transports.map((transport, i) => <CardOpener transport={transport} key={i} />)
            )}
          </>
        )}
        <NavigateCard
          dataCy="navigate"
          icon={faBed}
          header="Accommodation"
          navigateTo="/act/accommodation"
        />
      </div>
    </div>
  );
}

export default Transport;
